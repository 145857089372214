import { unpack } from "@/utils/imports"

export const EXTENSIONS = {
  sprengnetter_documents: {
    settings: {
      route: "sprengnetter-documents",
      link: "https://www.sprengnetter.de",
    },
    views: [
      {
        type: "iFrame",
        route: "portfolio/properties/:id",
        url: "/extensions/sso/sprengnetter_documents",
        query: "PLZ={zipCode}&ORT={city}&STR={street}&HNR={houseNumber}",
      },
    ],
  },
  areabutler: {
    settings: undefined,
    views: [
      {
        type: "iFrame",
        route: "portfolio/properties/:id",
        url: "/extensions/sso/areabutler",
      },
      {
        type: "propertyWriter",
        url: "/extensions/sso/areabutler",
      },
    ],
  },
  justhome: {
    settings: { route: "extensions/justhome" },
    views: [
      {
        route: "portfolio/properties/:id",
        type: "sso_iframe",
        url: "/extensions/justhome",
      },
    ],
  },
}

export const CRM_EXTENSIONS = Object.entries(EXTENSIONS).reduce((acc, [extensionName, { views }]) => {
  views.forEach(view => (acc[view.route] = (acc[view.route] ?? []).concat([extensionName])))
  return acc
}, {})

export const VIEW_EXTENSIONS = Object.entries(EXTENSIONS).reduce((acc, [extensionName, { views }]) => {
  views.forEach(view => {
    if (view.type === "iFrame") acc[view.route] = Object.assign(acc[view.route] ?? {}, { [extensionName]: view })
  })
  return acc
}, {})

export const COMPONENT_EXTENSIONS = Object.entries(EXTENSIONS).reduce((acc, [extensionName, { views }]) => {
  views.forEach(view => {
    if (view.type !== "iFrame") acc[view.type] = Object.assign(acc[view.type] ?? {}, { [extensionName]: view })
  })
  return acc
}, {})

export const SETTINGS_EXTENSIONS = Object.entries(EXTENSIONS).reduce((acc, [extensionName, { settings }]) => {
  if (settings) acc[extensionName] = settings
  return acc
}, {})

export const EXTENSIONS_LOGO_MAPPING = {
  mailchimp: unpack(
    import.meta.glob("@/assets/images/mailchimp.png", { import: "default", query: "?url", eager: true })
  ),
  brevo: unpack(import.meta.glob("@/assets/images/brevo.svg", { import: "default", query: "?url", eager: true })),
  mailgun: unpack(import.meta.glob("@/assets/images/mailgun.png", { import: "default", query: "?url", eager: true })),
  three_cx: unpack(import.meta.glob("@/assets/images/3cx.svg", { import: "default", query: "?url", eager: true })),
  messagebird: unpack(
    import.meta.glob("@/assets/images/messagebird.png", { import: "default", query: "?url", eager: true })
  ),
  docusign: unpack(import.meta.glob("@/assets/images/docusign.png", { import: "default", query: "?url", eager: true })),
  paperless: unpack(
    import.meta.glob("@/assets/images/extension/paperless.svg", { import: "default", query: "?url", eager: true })
  ),
  placetel: unpack(import.meta.glob("@/assets/images/placetel.svg", { import: "default", query: "?url", eager: true })),
  openai: unpack(import.meta.glob("@/assets/images/openai.svg", { import: "default", query: "?url", eager: true })),
  energieausweis48: unpack(
    import.meta.glob("@/assets/images/energieausweis48.png", { import: "default", query: "?url", eager: true })
  ),
  bottimmo: unpack(import.meta.glob("@/assets/images/bottimmo.png", { import: "default", query: "?url", eager: true })),
  sprengnetter_documents: unpack(
    import.meta.glob("@/assets/images/sprengnetter.svg", { import: "default", query: "?url", eager: true })
  ),
  propnow: unpack(import.meta.glob("@/assets/images/propnow.svg", { import: "default", query: "?url", eager: true })),
  deepl: unpack(import.meta.glob("@/assets/images/deepl.png", { import: "default", query: "?url", eager: true })),
  sipgate: unpack(import.meta.glob("@/assets/images/sipgate.svg", { import: "default", query: "?url", eager: true })),
  task_review: unpack(
    import.meta.glob("@/assets/images/taskreview.png", { import: "default", query: "?url", eager: true })
  ),
  frymo: unpack(import.meta.glob("@/assets/images/frymo.svg", { import: "default", query: "?url", eager: true })),
  zapier: unpack(import.meta.glob("@/assets/images/zapier.svg", { import: "default", query: "?url", eager: true })),
  commissionsplit: unpack(
    import.meta.glob("@/assets/images/propstack-promissionsplit.png", { import: "default", query: "?url", eager: true })
  ),
  wpimport: unpack(
    import.meta.glob("@/assets/images/wp-import-propstack.svg", { import: "default", query: "?url", eager: true })
  ),
  local_hero: unpack(
    import.meta.glob("@/assets/images/extension/scout-localhero.png", { import: "default", query: "?url", eager: true })
  ),
  offmarket: unpack(
    import.meta.glob("@/assets/images/extension/scout-offmarket.png", { import: "default", query: "?url", eager: true })
  ),
  areabutler: unpack(
    import.meta.glob("@/assets/images/extension/areabutler.svg", { import: "default", query: "?url", eager: true })
  ),
  sample_contracts: unpack(
    import.meta.glob("@/assets/images/extension/sven-johns.svg", { import: "default", query: "?url", eager: true })
  ),
  justhome: unpack(
    import.meta.glob("@/assets/images/extension/justhome/justhome-logo.svg", {
      import: "default",
      as: "url",
      eager: true,
    })
  ),
}

export const propstackIntegrationMapping = t => ({
  mailchimp: {
    name: t("integrations.mailchimp.name"),
    summary: t("integrations.mailchimp.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["mailchimp"],
  },
  brevo: {
    name: t("integrations.brevo.name"),
    summary: t("integrations.brevo.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["brevo"],
  },
  mailgun: {
    name: t("integrations.mailgun.name"),
    summary: t("integrations.mailgun.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["mailgun"],
  },
  three_cx: {
    name: t("integrations.three_cx.name"),
    summary: t("integrations.three_cx.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["three_cx"],
  },
  messagebird: {
    name: t("integrations.messagebird.name"),
    summary: t("integrations.messagebird.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["messagebird"],
  },
  docusign: {
    name: t("integrations.docusign.name"),
    summary: t("integrations.docusign.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["docusign"],
  },
  paperless: {
    name: t("integrations.paperless.name"),
    summary: t("integrations.paperless.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["paperless"],
  },
  placetel: {
    name: t("integrations.placetel.name"),
    summary: t("integrations.placetel.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["placetel"],
  },
  openai: {
    name: t("integrations.openai.name"),
    summary: t("integrations.openai.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["openai"],
  },
  energieausweis48: {
    name: t("integrations.energieausweis48.name"),
    summary: t("integrations.energieausweis48.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["energieausweis48"],
  },
  bottimmo: {
    name: t("integrations.bottimmo.name"),
    summary: t("integrations.bottimmo.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["bottimmo"],
  },
  sprengnetter_documents: {
    name: t("integrations.sprengnetter_documents.name"),
    summary: t("integrations.sprengnetter_documents.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["sprengnetter_documents"],
  },
  propnow: {
    name: t("integrations.propnow.name"),
    summary: t("integrations.propnow.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["propnow"],
  },
  deepl: {
    name: t("integrations.deepl.name"),
    summary: t("integrations.deepl.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["deepl"],
  },
  sipgate: {
    name: t("integrations.sipgate.name"),
    summary: t("integrations.sipgate.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["sipgate"],
  },
  task_review: {
    name: t("integrations.task_review.name"),
    summary: t("integrations.task_review.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["task_review"],
  },
  frymo: {
    name: t("integrations.frymo.name"),
    summary: t("integrations.frymo.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["frymo"],
  },
  zapier: {
    name: t("integrations.zapier.name"),
    summary: t("integrations.zapier.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["zapier"],
  },
  commissionsplit: {
    name: t("integrations.commissionsplit.name"),
    summary: t("integrations.commissionsplit.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["commissionsplit"],
  },
  wpimport: {
    name: t("integrations.wpimport.name"),
    summary: t("integrations.wpimport.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["wpimport"],
  },
  // immoscout24: { name: t('integrations.immoscout24.name'), summary: t('integrations.immoscout24.summary'), imageUrl: EXTENSIONS_LOGO_MAPPING['immoscout24'] },
  local_hero: {
    name: t("integrations.local_hero.name"),
    summary: t("integrations.local_hero.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["local_hero"],
  },
  offmarket: {
    name: t("integrations.offmarket.name"),
    summary: t("integrations.offmarket.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["offmarket"],
  },
  areabutler: {
    name: t("integrations.areabutler.name"),
    summary: t("integrations.areabutler.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["areabutler"],
  },
  sample_contracts: {
    name: t("integrations.sample_contracts.name"),
    summary: t("integrations.sample_contracts.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["sample_contracts"],
  },
  justhome: {
    name: t("integrations.justhome.name"),
    summary: t("integrations.justhome.summary"),
    imageUrl: EXTENSIONS_LOGO_MAPPING["justhome"],
  },
})
