const getCookies = () =>
  document.cookie
    .split(";")
    .map(entry => entry.trim().split("="))
    .reduce((prev, [key, value]) => ({ ...prev, [key]: value }), {})

export const isAuthenticated = () => {
  const cookies = getCookies()
  return !!cookies["auth_token"]
}

export const isBackdoor = () => {
  const cookies = getCookies()
  return cookies["backdoor"] === "true" && !(window as any).override_backdoor
}

const adminRoute = (path: string) => `/admin/${path}`

const adminRoutes = (...paths: string[]) => paths.map(p => adminRoute(p))

export const adminPermissionTree: [string[], (broker: any, shop?: any) => boolean, boolean?][] = [
  [adminRoutes("locations"), broker => broker.admin || broker.canEditUnits],
  [adminRoutes("newsletter"), broker => broker.admin || broker.canSeeMarketing],
  [adminRoutes("super_groups"), broker => broker.admin || broker.canEditGroups],
  [adminRoutes("settings/pipelines"), broker => broker.admin || broker.canEditPipelines],
  [adminRoutes("shopwindows"), broker => broker.admin || broker.canEditUnits],
  [adminRoutes("booking_calendars"), broker => broker.admin || broker.canChangeTasks],
  [adminRoutes("settings/variables", "letter_templates"), broker => broker.admin || broker.canEditLetterTemplates],
  [adminRoutes("franchise"), (_, shop) => shop.franchiser],
  [adminRoutes("settings/shop"), broker => broker.admin || broker.canEditShopData],
  [adminRoutes("shop/micro_sites"), broker => broker.admin || broker.canEditLandingpages],
  [adminRoutes("settings/gdpr"), broker => broker.admin || broker.canEditGdprData],
  [adminRoutes("connections"), broker => broker.admin || broker.canEditPortals],
  [adminRoutes("extensions"), broker => broker.admin || broker.canEditExtensions],
  [adminRoutes("mail_accounts"), broker => broker.admin || broker.canEditBrokers],
  [adminRoutes("settings/contacts", "settings/properties", "settings/deals", "custom_fields"), broker => broker.admin],
  [
    adminRoutes(
      "mailgun",
      "messagebird",
      "automations",
      "rights",
      "settings/tasks",
      "settings/lead_inquiries",
      "settings/media",
      "settings/reports"
    ),
    broker => broker.admin,
  ],
  [adminRoutes("settings/api_keys"), broker => broker.canEditApiKeys],
  [adminRoutes("settings/teams"), broker => broker.canEditTeams],
  [adminRoutes("settings/pdf_exposees"), broker => broker.canEditPdfExposeeSettings],
  [adminRoutes("settings/property_report"), broker => broker.canEditPropertyReportSettings],
  [adminRoutes("settings/events"), broker => broker.canEditEventSettings],
  [adminRoutes("departments"), broker => broker.canEditDepartments],
  [adminRoutes("settings/saved_queries"), broker => broker.canEditSavedQuerySettings],
  [adminRoutes("shop/lead_score"), broker => broker.canEditLeadScoreSettings],
  [adminRoutes("settings/", "settings"), broker => broker.admin, true],
  [
    adminRoutes("shop/immo_valuation"),
    (broker, shop) => broker.admin && shop.activeFeatures.includes("immo_valuation"),
  ],
  [
    adminRoutes("shop/tipster_portal"),
    (broker, shop) => broker.admin && shop.activeFeatures.includes("tipster_portal"),
  ],
  [
    adminRoutes("shop/valuations", "shop/valuations/sprengnetter"),
    (broker, shop) =>
      (broker.admin || broker.canSeeMarketing) &&
      (shop.activeFeatures.includes("ph_valuation") || shop.activeFeatures.includes("sn_valuation")),
  ],
  [adminRoutes("shop/extensions/propnow"), (broker, _shop) => broker.admin || broker.canSeeMarketing],
  [adminRoutes("settings/task_pipelines"), (_broker, shop) => shop.activeFeatures.includes("task_pipelines")],
  [adminRoutes("settings/sample_contracts"), (_broker, shop) => shop.activeFeatures.includes("sample_contracts")],
]

export const routeGuard = (route: string, broker: any, shop: any) => {
  if (route === "/") return true
  return adminPermissionTree.reduce((agg, [routes, validator, exact = false]) => {
    return agg && routes.every(r => ((exact ? route === r : route.startsWith(r)) ? validator(broker, shop) : true))
  }, true)
}
